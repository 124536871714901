import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// GEN_REGISTER_COMPONENT_START

Vue.component('OperationsChart', () => import('@/components/charts/OperationsChart/OperationsChart'));
Vue.component('stopFormHeader', () => import('@/components/stop/StopFormHeader.vue'));
Vue.component('stopgrid', () => import('@/components/stop/StopGrid.vue'));
Vue.component('logaiseventextendedgrid', () => import('@/components/logaiseventextended/LogaiseventextendedGrid.vue'));
Vue.component('resourcesgrid', () => import('@/components/resources/ResourcesGrid.vue'));
Vue.component('operationgrid', () => import('@/components/operation/OperationGrid.vue'));
Vue.component('operationform', () => import('@/components/operation/OperationForm.vue'));
Vue.component('operationFormHeader', () => import('@/components/operation/OperationFormHeader.vue'));
Vue.component('consignmentchangegrid', () => import('@/components/consignmentchange/ConsignmentchangeGrid.vue'));
Vue.component('pilotagegrid', () => import('@/components/pilotage/PilotageGrid.vue'));
Vue.component('pilotageform', () => import('@/components/pilotage/PilotageForm.vue'));
Vue.component('pilotageFormHeader', () => import('@/components/pilotage/PilotageFormHeader.vue'));
Vue.component('mooring-grid', () => import('@/components/mooring/MooringGrid.vue'));
Vue.component('mooring-form', () => import('@/components/mooring/MooringForm.vue'));
Vue.component('mooringFormHeader', () => import('@/components/mooring/MooringFormHeader.vue'));
Vue.component('towage-grid', () => import('@/components/towage/TowageGrid.vue'));
Vue.component('towage-form', () => import('@/components/towage/TowageForm.vue'));
Vue.component('towageFormHeader', () => import('@/components/towage/TowageFormHeader.vue'));
Vue.component('visitmmppform', () => import('@/components/visit/visitmmpp/VisitmmppForm.vue'));
Vue.component('visitsolasform', () => import('@/components/visit/visitsolas/VisitsolasForm.vue'));
Vue.component('visitispsform', () => import('@/components/visit/visitisps/VisitispsForm.vue'));
Vue.component('visittemplateform', () => import('@/components/visit/VisitTemplateForm.vue'));
Vue.component('visitFormHeader', () => import('@/components/visit/VisitFormHeader.vue'));
Vue.component('stop-form', () => import('@/components/stop/StopForm.vue'));
Vue.component('movement-form', () => import('@/components/movement/MovementForm.vue'));
Vue.component('movementFormHeader', () => import('@/components/movement/MovementFormHeader.vue'));
Vue.component('movementgrid', () => import('@/components/movement/MovementGrid.vue'));
Vue.component('visit-form', () => import('@/components/visit/VisitForm.vue'));
Vue.component('attributeform', () => import('@/components/attribute/AttributeForm.vue'));
Vue.component('attributequickform', () => import('@/components/attribute/AttributeQuickForm.vue'));
Vue.component('ship-form', () => import('@/components/ship/ShipForm.vue'));

Vue.component('shipcertgrid', () => import('@/components/shipcert/ShipcertGrid.vue'));
Vue.component('movementsequencestep-grid', () => import('@/components/movementsequencestep/MovementsequencestepGrid.vue'));
Vue.component('visitmodals', () => import('@/components/visit/VisitModals.vue'));
Vue.component('attributegrid', () => import('@/components/attribute/AttributeGrid.vue'));
Vue.component('berthblockgrid', () => import('@/components/berthblock/BerthblockGrid.vue'));
Vue.component('portauthorityvariablegrid', () => import('@/components/portauthorityvariable/PortauthorityvariableGrid.vue'));
Vue.component('locationsequenceheader-grid', () => import('@/components/locationsequenceheader/LocationsequenceheaderGrid.vue'));
Vue.component('locationsequencestep-grid', () => import('@/components/locationsequencestep/LocationsequencestepGrid.vue'));
Vue.component('resourceallocationgrid', () => import('@/components/resourceallocation/ResourceallocationGrid.vue'));
Vue.component('resourceallocationFormHeader', () => import('@/components/resourceallocation/ResourceallocationFormHeader.vue'));
Vue.component('resourceallocationmodals', () => import('@/components/resourceallocation/ResourceallocationModals.vue'));
Vue.component('stop-modals', () => import('@/components/stop/StopModals.vue'));
Vue.component('resourceblockgrid', () => import('@/components/resourceblock/ResourceblockGrid.vue'));
Vue.component('visitstatustransitiongrid', () => import('@/components/visitstatustransition/VisitstatustransitionGrid.vue'));
Vue.component('stopstatustransitiongrid', () => import('@/components/stopstatustransition/StopstatustransitionGrid.vue'));
Vue.component('grid-export-btn', () => import('@/components/resources/GridExportBtn'));
Vue.component('resourcerequestmodals', () => import('@/components/resourcerequest/ResourcerequestModals.vue'));
Vue.component('resourcerequest-form-header', () => import('@/components/resourcerequest/ResourcerequestFormHeader.vue'));
Vue.component('dockgrid', () => import('@/components/dock/DockGrid.vue'));
Vue.component('dockform', () => import('@/components/dock/DockForm.vue'));
Vue.component('repairgrid', () => import('@/components/repair/RepairGrid.vue'));
Vue.component('repairform', () => import('@/components/repair/RepairForm.vue'));
Vue.component('repairFormHeader', () => import('@/components/repair/RepairFormHeader.vue'));
Vue.component('technicalnauticalserviceFormHeader', () => import('@/components/technicalnauticalservice/TechnicalnauticalserviceFormHeader.vue'));
Vue.component('bookingFormHeader', () => import('@/components/booking/BookingFormHeader.vue'));
Vue.component('incidentFormHeader', () => import('@/components/incident/IncidentFormHeader.vue'));
Vue.component('stopanchorageFormHeader', () => import('@/components/anchorage/AnchorageFormHeader.vue'));

Vue.component('visitstatuspa-grid', () => import('@/components/visitstatuspa/VisitstatuspaGrid'));
Vue.component('visitstatuspa-form', () => import('@/components/visitstatuspa/VisitstatuspaForm'));
Vue.component('portauthorityconfig-grid', () => import('@/components/portauthorityconfig/PortauthorityconfigGrid'));
Vue.component('portauthorityconfig-form', () => import('@/components/portauthorityconfig/PortauthorityconfigForm'));
Vue.component('shippositioning-grid', () => import('@/components/shippositioning/ShippositioningGrid'));
Vue.component('finishedshippositioninggrid', () => import('@/components/shippositioning/FinishedShippositioningGrid'));
Vue.component('vterminaltimesgrid', () => import('@/components/vterminaltimes/VterminaltimesGrid'));

Vue.component('giswebdetailgrid', () => import('@/components/gisweb/detail/GiswebDetailGrid.vue'));

// GEN_REGISTER_COMPONENT_END

const puiRoutes = [
	{ path: '/', redirect: 'home' },
	{ path: 'usersettings', component: () => import('@Pui9Admin/src/components/admin/puiuser/PuiUserSettingsForm.vue') },
	{ path: 'puiaudit', component: () => import('@Pui9Admin/src/components/config/puiaudit/PuiAuditGrid.vue') },
	{ path: 'session', component: () => import('@Pui9Admin/src/components/config/puisession/PuiSessionGrid.vue') },
	{ path: 'puivariable', component: () => import('@Pui9Admin/src/components/config/puivariable/PuiVariableGrid.vue') },
	{
		path: '(.*/)?puivariable/:method/:pk',
		props: true,
		component: () => import('@Pui9Admin/src/components/config/puivariable/PuiVariableForm.vue')
	},
	{ path: 'puilanguage', component: () => import('@Pui9Admin/src/components/config/puilanguage/PuiLanguageGrid.vue') },
	{
		path: '(.*/)?puilanguage/:method/:pk',
		props: true,
		component: () => import('@Pui9Admin/src/components/config/puilanguage/PuiLanguageForm.vue')
	},

	{ path: 'puiuser', component: () => import('@Pui9Admin/src/components/admin/puiuser/PuiUserGrid.vue') },
	{ path: '(.*)puiuser/:method/:pk', props: true, component: () => import('@Pui9Admin/src/components/admin/puiuser/PuiUserForm.vue') },
	{ path: 'puiprofile', component: () => import('@Pui9Admin/src/components/admin/puiprofile/PuiProfileGrid.vue') },
	{ path: '(.*)puiprofile/:method/:pk', props: true, component: () => import('@Pui9Admin/src/components/admin/puiprofile/PuiProfileForm.vue') },
	{ path: 'puifunctionality', component: () => import('@Pui9Admin/src/components/admin/puifunctionality/PuiFunctionalityGrid.vue') },

	{ path: 'puiwidget', component: () => import('@Pui9Dashboard/src/components/puiwidget/PuiWidgetGrid.vue') },
	{ path: '(.*/)?puiwidget/:method/:pk', props: true, component: () => import('@Pui9Dashboard/src/components/puiwidget/PuiWidgetForm.vue') },
	{ path: 'puidashboard', component: () => import('@Pui9Dashboard/src/components/puidashboard/PuiDashboardGrid.vue') },
	{
		path: '(.*/)?puidashboard/:method/:pk',
		props: true,
		component: () => import('@Pui9Dashboard/src/components/puidashboard/PuiDashboardForm.vue')
	},
	{ path: 'puiwidgetoverview', component: () => import('@Pui9Dashboard/src/components/PuiWidgetOverview.vue') },
	{ path: 'puidashboardoverview', component: () => import('@Pui9Dashboard/src/components/PuiDashboardOverview.vue') },
	{ path: 'puialertconfiguration', component: () => import('@Pui9Alerts/src/components/puialerts/PuiAlertConfigurationGrid.vue') },
	{
		path: '(.*/)?puialertconfiguration/:method/:pk',
		props: true,
		component: () => import('@Pui9Alerts/src/components/puialerts/PuiAlertConfigurationForm.vue')
	},
	{ path: 'puialert', component: () => import('@Pui9Alerts/src/components/puialerts/PuiAlertGrid.vue') },
	{ path: 'puidocument', component: () => import('@Pui9Documents/src/components/PuiDocumentGrid') },
	{
		path: '(.*/)?puidocument/:method/:pk',
		props: true,
		component: () => import('@Pui9Documents/src/components/PuiDocumentForm.vue')
	}
];

const appRoutes = [
	// GEN_ROUTES_START
	{ path: 'home', component: () => import('@/components/OperationsMapAis') },
	{ path: 'operationsuser', component: () => import('@/components/operationsuser/OperationsUserGrid') },
	{ path: '(.*/)?operationsuser/:method/:pk', props: true, component: () => import('@/components/operationsuser/OperationsUserForm') },
	{ path: 'shipsituation', component: () => import('@/components/OperationsMapPeriods') },
	{
		path: 'shipsituation/:method/:idstop',
		props: true,
		component: () => import('@/components/OperationsMapPeriods')
	},
	{ path: 'berthplanner', component: () => import('@/components/berthplanner/DBerthPlannerContainer') },
	{ path: 'dberthplanner', component: () => import('@/components/berthplanner/DBerthPlannerContainer') },
	{ path: 'dresourceplanner', component: () => import('@/components/resourceplanner/DResourcePlannerContainer') },
	{ path: 'visitsgantt', component: () => import('@/components/GanttContainer') },
	{ path: 'kpislc', component: () => import('@/components/kpis/KPIsLazaroCardenas.vue') },
	{ path: 'kpis', component: () => import('@/components/kpis/KPIsOperations.vue') },
	{ path: 'stopmanoeuvre', component: () => import('@/components/stopmanoeuvre/StopManoeuvreGrid.vue') },
	{ path: 'operationsprofilecompany', component: () => import('@/components/operationsprofilecompany/OperationsprofilecompanyGrid.vue') },
	{
		path: 'operationsprofilecompany/:method/:pk',
		props: true,
		component: () => import('@/components/operationsprofilecompany/OperationsprofilecompanyForm.vue')
	},
	{ path: 'contact', component: () => import('@/components/contact/ContactGrid.vue') },
	{
		path: 'contact/:method/:pk',
		props: true,
		component: () => import('@/components/contact/ContactForm.vue')
	},
	{ path: 'company', component: () => import('@/components/company/CompanyGrid.vue') },
	{
		path: 'company/:method/:pk',
		props: true,
		component: () => import('@/components/company/CompanyForm.vue')
	},
	{ path: 'ship', component: () => import('@/components/ship/ShipGrid.vue') },
	{
		path: 'ship/:method/:pk',
		props: true,
		component: () => import('@/components/ship/ShipForm.vue')
	},
	{
		path: 'ship/:method/:parentpk/shipcert/:method/:pk',
		props: true,
		component: () => import('@/components/shipcert/ShipcertForm.vue')
	},
	{ path: 'logaiseventextended', component: () => import('@/components/logaiseventextended/LogaiseventextendedGrid.vue') },
	{
		path: 'logaiseventextended/:method/:pk',
		props: true,
		component: () => import('@/components/logaiseventextended/LogaiseventextendedForm.vue')
	},
	{ path: 'consignmentchange', component: () => import('@/components/consignmentchange/ConsignmentchangeGrid.vue') },
	{
		path: 'consignmentchange/:method/:pk',
		props: true,
		component: () => import('@/components/consignmentchange/ConsignmentchangeGrid.vue')
	},
	{
		path: 'visit/:method/:pk/logaiseventextended/:method/:pk',
		props: true,
		component: () => import('@/components/logaiseventextended/LogaiseventextendedForm.vue')
	},
	{ path: 'visit', component: () => import('@/components/visit/VisitGrid.vue') },
	{
		path: 'visit/:method/:pk',
		props: true,
		component: () => import('@/components/visit/VisitForm.vue')
	},
	{
		path: 'visit/:method/:visitpk/stop/:method/:pk',
		props: true,
		component: () => import('@/components/stop/StopForm.vue')
	},
	{
		path: 'visit/:method/:visitpk/stop/:method/:stoppk/operation/:method/:pk',
		props: true,
		component: () => import('@/components/operation/OperationForm.vue')
	},
	{
		path: 'visit/:method/:visitpk/stop/:method/:stoppk/operation/:method/:operationpk/resourceallocation/:method/:pk',
		props: true,
		component: () => import('@/components/resourceallocation/ResourceallocationForm.vue')
	},
	{
		path: 'visit/:method/:visitpk/operation/:method/:pk',
		props: true,
		component: () => import('@/components/operation/OperationForm.vue')
	},
	{
		path: 'visit/:method/:visitpk/operation/:method/:operationpk/resourceallocation/:method/:pk',
		props: true,
		component: () => import('@/components/resourceallocation/ResourceallocationForm.vue')
	},
	{
		path: 'visit/:method/:pk/movement/:method/:pk',
		props: true,
		component: () => import('@/components/movement/MovementForm.vue')
	},
	{
		path: 'visit/:method/:pk/movement/:method/:pk/resourceallocation/:method/:pk',
		props: true,
		component: () => import('@/components/resourceallocation/ResourceallocationForm.vue')
	},
	{
		path: 'visit/:method/:pk/movement/:method/:pk/movementsequencestep/:method/:pk',
		props: true,
		component: () => import('@/components/movementsequencestep/MovementsequencestepForm.vue')
	},
	{
		path: 'visit/:method/:visitpk/movement/:method/:parentpk/pilotage/:method/:pk',
		props: true,
		component: () => import('@/components/pilotage/PilotageForm.vue')
	},
	{
		path: 'visit/:method/:visitpk/pilotage/:method/:pk',
		props: true,
		component: () => import('@/components/pilotage/PilotageForm.vue')
	},
	{
		path: 'visit/:method/:visitpk/mooring/:method/:pk',
		props: true,
		component: () => import('@/components/mooring/MooringForm.vue')
	},
	{
		path: 'visit/:method/:visitpk/towage/:method/:pk',
		props: true,
		component: () => import('@/components/towage/TowageForm.vue')
	},
	{ path: 'stop', component: () => import('@/components/stop/StopGrid.vue') },
	{
		path: 'stop/:method/:pk',
		props: true,
		component: () => import('@/components/stop/StopForm.vue')
	},
	{
		path: 'stop/:method/:stoppk/operation/:method/:pk',
		props: true,
		component: () => import('@/components/operation/OperationForm.vue')
	},
	{
		path: 'stop/:method/:stoppk/operation/:method/:operationpk/resourceallocation/:method/:pk',
		props: true,
		component: () => import('@/components/resourceallocation/ResourceallocationForm.vue')
	},
	{
		path: 'stop/:method/:stoppk/resourceallocation/:method/:pk',
		props: true,
		component: () => import('@/components/resourceallocation/ResourceallocationForm.vue')
	},
	{ path: 'operation', component: () => import('@/components/operation/OperationGrid.vue') },
	{
		path: 'operation/:method/:pk',
		props: true,
		component: () => import('@/components/operation/OperationForm.vue')
	},
	{
		path: 'operation/:method/:operationpk/resourceallocation/:method/:pk',
		props: true,
		component: () => import('@/components/resourceallocation/ResourceallocationForm.vue')
	},
	{ path: 'mooring', component: () => import('@/components/mooring/MooringGrid.vue') },
	{
		path: 'mooring/:method/:pk',
		props: true,
		component: () => import('@/components/mooring/MooringForm.vue')
	},
	{
		path: 'mooring/:method/:pk/resourceallocation/:method/:pk',
		props: true,
		component: () => import('@/components/resourceallocation/ResourceallocationForm.vue')
	},
	{ path: 'pilotage', component: () => import('@/components/pilotage/PilotageGrid.vue') },
	{
		path: 'pilotage/:method/:pk',
		props: true,
		component: () => import('@/components/pilotage/PilotageForm.vue')
	},
	{
		path: 'pilotage/:method/:pk/resourceallocation/:method/:pk',
		props: true,
		component: () => import('@/components/resourceallocation/ResourceallocationForm.vue')
	},
	{ path: 'towage', component: () => import('@/components/towage/TowageGrid.vue') },
	{
		path: 'towage/:method/:pk',
		props: true,
		component: () => import('@/components/towage/TowageForm.vue')
	},
	{
		path: 'towage/:method/:pk/resourceallocation/:method/:pk',
		props: true,
		component: () => import('@/components/resourceallocation/ResourceallocationForm.vue')
	},
	{ path: 'movement', component: () => import('@/components/movement/MovementGrid.vue') },
	{
		path: 'movement/:method/:pk',
		props: true,
		component: () => import('@/components/movement/MovementForm.vue')
	},
	{
		path: 'movement/:method/:parentpk/pilotage/:method/:pk',
		props: true,
		component: () => import('@/components/pilotage/PilotageForm.vue')
	},
	{
		path: 'movement/:method/:pk/resourceallocation/:method/:pk',
		props: true,
		component: () => import('@/components/resourceallocation/ResourceallocationForm.vue')
	},
	{
		path: 'movement/:method/:parentpk/movementsequencestep/:method/:pk',
		props: true,
		component: () => import('@/components/movementsequencestep/MovementsequencestepForm.vue')
	},
	{ path: 'arrivalmovement', component: () => import('@/components/movement/ArrivalMovementGrid.vue') },
	{
		path: 'arrivalmovement/:method/:pk',
		props: true,
		component: () => import('@/components/movement/MovementForm.vue')
	},
	{
		path: 'arrivalmovement/:method/:pk/resourceallocation/:method/:pk',
		props: true,
		component: () => import('@/components/resourceallocation/ResourceallocationForm.vue')
	},
	{
		path: 'arrivalmovement/:method/:parentpk/pilotage/:method/:pk',
		props: true,
		component: () => import('@/components/pilotage/PilotageForm.vue')
	},
	{
		path: 'arrivalmovement/:method/:parentpk/movementsequencestep/:method/:pk',
		props: true,
		component: () => import('@/components/movementsequencestep/MovementsequencestepForm.vue')
	},
	{ path: 'departuremovement', component: () => import('@/components/movement/DepartureMovementGrid.vue') },
	{
		path: 'departuremovement/:method/:pk',
		props: true,
		component: () => import('@/components/movement/MovementForm.vue')
	},
	{
		path: 'departuremovement/:method/:pk/resourceallocation/:method/:pk',
		props: true,
		component: () => import('@/components/resourceallocation/ResourceallocationForm.vue')
	},
	{
		path: 'departuremovement/:method/:parentpk/pilotage/:method/:pk',
		props: true,
		component: () => import('@/components/pilotage/PilotageForm.vue')
	},
	{
		path: 'departuremovement/:method/:parentpk/movementsequencestep/:method/:pk',
		props: true,
		component: () => import('@/components/movementsequencestep/MovementsequencestepForm.vue')
	},
	{ path: 'portauthority', component: () => import('@/components/portauthority/PortauthorityGrid.vue') },
	{
		path: 'portauthority/:method/:pk',
		props: true,
		component: () => import('@/components/portauthority/PortauthorityForm.vue')
	},
	{ path: 'shiptypensw', component: () => import('@/components/shiptypensw/ShiptypenswGrid.vue') },
	{
		path: 'shiptypensw/:method/:pk',
		props: true,
		component: () => import('@/components/shiptypensw/ShiptypenswForm.vue')
	},
	{ path: 'shiptypepa', component: () => import('@/components/shiptypepa/ShiptypepaGrid.vue') },
	{
		path: 'shiptypepa/:method/:pk',
		props: true,
		component: () => import('@/components/shiptypepa/ShiptypepaForm.vue')
	},
	{ path: 'berth', component: () => import('@/components/berth/BerthGrid.vue') },
	{
		path: 'berth/:method/:pk',
		props: true,
		component: () => import('@/components/berth/BerthForm.vue')
	},
	{ path: 'subscription', component: () => import('@/components/subscription/SubscriptionGrid.vue') },
	{ path: 'subscriptionstop', component: () => import('@/components/subscriptionstop/SubscriptionstopGrid.vue') },
	{
		path: 'berth/:method/:parentpk/berthblock/:method/:pk',
		props: true,
		component: () => import('@/components/berthblock/BerthblockForm.vue')
	},
	{
		path: 'berth/:method/:parentpk/locationsequenceheader/:method/:pk',
		props: true,
		component: () => import('@/components/locationsequenceheader/LocationsequenceheaderForm.vue')
	},
	{
		path: 'berth/:method/:parentpk/locationsequenceheader/:method/:parentpk/locationsequencestep/:method/:pk',
		props: true,
		component: () => import('@/components/locationsequencestep/LocationsequencestepForm.vue')
	},
	{ path: 'resourcetype', component: () => import('@/components/resourcetype/ResourcetypeGrid.vue') },
	{
		path: 'resourcetype/:method/:pk',
		props: true,
		component: () => import('@/components/resourcetype/ResourcetypeForm.vue')
	},
	{ path: 'attribute', component: () => import('@/components/attribute/AttributeGrid') },
	{ path: '(.*/)?attribute/:method/:pk', props: true, component: () => import('@/components/attribute/AttributeForm') },
	{ path: 'resources', component: () => import('@/components/resources/ResourcesGrid.vue') },
	{
		path: 'resources/:method/:pk',
		props: true,
		component: () => import('@/components/resources/ResourcesForm.vue')
	},
	{
		path: 'resourcetype/:method/:parentpk/resources/:method/:pk',
		props: true,
		component: () => import('@/components/resources/ResourcesForm.vue')
	},

	{ path: 'resourceallocation', component: () => import('@/components/resourceallocation/ResourceallocationGrid.vue') },
	{
		path: 'resourceallocation/:method/:pk',
		props: true,
		component: () => import('@/components/resourceallocation/ResourceallocationForm.vue')
	},

	{ path: 'portauthorityvariable', component: () => import('@/components/portauthorityvariable/PortauthorityvariableGrid.vue') },
	{
		path: 'portauthority/:method/:pk/portauthorityvariable/:method/:pk',
		props: true,
		component: () => import('@/components/portauthorityvariable/PortauthorityvariableForm.vue')
	},
	{ path: 'pilot', component: () => import('@/components/pilot/PilotGrid.vue') },
	{
		path: 'pilot/:method/:pk',
		props: true,
		component: () => import('@/components/pilot/PilotForm.vue')
	},
	{ path: 'shiptypeais', component: () => import('@/components/shiptypeais/ShiptypeaisGrid.vue') },
	{
		path: 'shiptypeais/:method/:pk',
		props: true,
		component: () => import('@/components/shiptypeais/ShiptypeaisForm.vue')
	},
	{ path: 'shipserviceap', component: () => import('@/components/shipserviceap/ShipserviceapGrid.vue') },
	{
		path: 'shipserviceap/:method/:pk',
		props: true,
		component: () => import('@/components/shipserviceap/ShipserviceapForm.vue')
	},
	{ path: 'resourcerequest', component: () => import('@/components/resourcerequest/ResourcerequestGrid.vue') },
	{
		path: 'resourcerequest/:method/:pk',
		props: true,
		component: () => import('@/components/resourcerequest/ResourcerequestForm.vue')
	},
	{
		path: 'resourcerequest/:method/:resourcerequestpk/resourceallocation/:method/:pk',
		props: true,
		component: () => import('@/components/resourceallocation/ResourceallocationForm.vue')
	},
	{
		path: '(.*)resources/:method/:parentpk/resourceblock/:method/:pk',
		props: true,
		component: () => import('@/components/resourceblock/ResourceblockForm.vue')
	},
	{ path: 'locationsequenceheader', component: () => import('@/components/locationsequenceheader/LocationsequenceheaderGrid.vue') },
	{
		path: 'locationsequenceheader/:method/:pk',
		props: true,
		component: () => import('@/components/locationsequenceheader/LocationsequenceheaderForm.vue')
	},
	{
		path: 'locationsequenceheader/:method/:parentpk/locationsequencestep/:method/:pk',
		props: true,
		component: () => import('@/components/locationsequencestep/LocationsequencestepForm.vue')
	},
	{ path: 'vstopanchorage', component: () => import('@/components/anchorage/AnchorageGrid.vue') },
	{
		path: 'vstopanchorage/:method/:pk',
		props: true,
		component: () => import('@/components/stop/StopForm.vue')
	},
	{ path: 'visitstatustransition', component: () => import('@/components/visitstatustransition/VisitstatustransitionGrid.vue') },
	{
		path: 'portauthority/:method/:pk/visitstatustransition/:method/:pk',
		props: true,
		component: () => import('@/components/visitstatustransition/VisitstatustransitionForm.vue')
	},
	{ path: 'stopstatustransition', component: () => import('@/components/stopstatustransition/StopstatustransitionGrid.vue') },
	{
		path: 'portauthority/:method/:pk/stopstatustransition/:method/:pk',
		props: true,
		component: () => import('@/components/stopstatustransition/StopstatustransitionForm.vue')
	},
	{ path: 'vfinishedstopduration', component: () => import('@/components/indicators/FinishedStopDurationGrid.vue') },
	{ path: 'vresourceallocationduration', component: () => import('@/components/indicators/ResourceAllocationDurationGrid.vue') },
	{ path: 'vactualstepsequence', component: () => import('@/components/indicators/ActualStepSequenceGrid.vue') },
	{ path: 'alert', component: () => import('@/components/alert/AlertGrid.vue') },
	{
		path: 'alert/:method/:pk',
		props: true,
		component: () => import('@/components/alert/AlertForm.vue')
	},
	{ path: 'inconsistency', component: () => import('@/components/inconsistency/InconsistencyGrid.vue') },
	{
		path: 'inconsistency/:method/:pk',
		props: true,
		component: () => import('@/components/inconsistency/InconsistencyForm.vue')
	},
	{
		path: 'technicalnauticalservice',
		component: () => import('@/components/technicalnauticalservice/TechnicalnauticalserviceGrid.vue')
	},
	{
		path: 'technicalnauticalservice/:method/:pk',
		props: true,
		component: () => import('@/components/technicalnauticalservice/TechnicalnauticalserviceForm.vue')
	},
	{
		path: 'stopstatuspa',
		component: () => import('@/components/stopstatuspa/StopstatuspaGrid.vue')
	},
	{
		path: 'stopstatuspa/:method/:pk',
		props: true,
		component: () => import('@/components/stopstatuspa/StopstatuspaForm.vue')
	},
	{
		path: 'booking',
		component: () => import('@/components/booking/BookingGrid.vue')
	},
	{
		path: 'booking/:method/:pk',
		props: true,
		component: () => import('@/components/booking/BookingForm.vue')
	},
	{ path: 'incident', component: () => import('@/components/incident/IncidentGrid.vue') },
	{
		path: 'incident/:method/:pk',
		props: true,
		component: () => import('@/components/incident/IncidentForm.vue')
	},
	{
		path: 'dock',
		component: () => import('@/components/dock/DockGrid.vue')
	},
	{
		path: '(.*)dock/:method/:pk',
		props: true,
		component: () => import('@/components/dock/DockForm.vue')
	},
	{
		path: 'repair',
		component: () => import('@/components/repair/RepairGrid.vue')
	},
	{
		path: '(.*)repair/:method/:pk',
		props: true,
		component: () => import('@/components/repair/RepairForm.vue')
	},
	{
		path: 'visitstatuspa',
		component: () => import('@/components/visitstatuspa/VisitstatuspaGrid')
	},
	{
		path: '(.*)visitstatuspa/:method/:pk',
		props: true,
		component: () => import('@/components/visitstatuspa/VisitstatuspaForm')
	},
	{
		path: 'portauthorityconfig',
		component: () => import('@/components/portauthorityconfig/PortauthorityconfigGrid')
	},
	{
		path: '(.*)portauthorityconfig/:method/:pk',
		props: true,
		component: () => import('@/components/portauthorityconfig/PortauthorityconfigForm')
	},
	{
		path: 'shippositioning',
		component: () => import('@/components/shippositioning/ShippositioningGrid')
	},
	{
		path: 'shippositioning/:method/:pk',
		props: true,
		component: () => import('@/components/shippositioning/ShippositioningForm')
	},
	{
		path: 'finishedshippositioning',
		component: () => import('@/components/shippositioning/FinishedShippositioningGrid')
	},
	{
		path: 'finishedshippositioning/:method/:pk',
		props: true,
		component: () => import('@/components/shippositioning/ShippositioningForm')
	},
	{
		path: 'gisweb',
		props: true,
		component: () => import('@/components/gisweb/GiswebContainer')
	},
	{
		path: '(.*)gisweb/:method/:pk',
		props: true,
		component: () => import('@/components/gisweb/detail/GiswebDetail')
	},
	{
		path: 'terminaltimes',
		component: () => import('@/components/vterminaltimes/VterminaltimesGrid')
	}

	// GEN_ROUTES_END
];

function setAuthRequired(route) {
	return { ...route, meta: { ...route.meta, requiresAuth: true } };
}

const mergedRouter = {
	mode: 'history',
	hash: false,
	base: 'operations',
	routes: [
		{
			path: '/resetPassword',
			component: () => import('@Pui9Base/src/components/PuiUserResetPassword')
		},
		{
			path: '/login',
			component: () => import('@/components/login/OperationsLogin')
		},
		{
			path: '/keycloaklogin',
			component: () => import('@Pui9Login/src/components/PuiKeycloakLogin')
		},
		{
			path: '/',
			component: () => import('@Pui9Base/src/components/PuiBaseLayout'),
			children: puiRoutes.concat(appRoutes).map(setAuthRequired)
		}
	]
};

export default new VueRouter(mergedRouter);
