export const BASEMAP_ID = 'basemap-id';

export const VESSEL_LAYER_ID = 'vesselLayer'; // Capa de buques.

// Track layers
export const AIS_TRACK_EVENT_POINT_LAYER_ID = 'aisTrackEventPointLayer';
export const AIS_TRACK_EVENT_VESSEL_LINE_LAYER_ID = 'aisTrackEventVesselLineLayer';
export const AIS_TRACK_EVENT_VESSEL_LINE_POINT_LAYER_ID = 'aisTrackEventVesselLinePointLayer';

// Movement layer
export const MOVEMENT_SEQUENCE_LAYER_ID = 'movementSequenceLayerId';

export const VESEEL_INFO_LAYER_ID = 'vesselInfoLayer'; // Esta dentro de una carpeta GANTT.

export const STOPS_LAYER_ID = 'stopsLayer'; // Capa de stops de los buques. Se crea y se pinta. // Se utiliza en las pestañas
export const SELECT_STOP_LAYER_ID = 'selectStopLayer'; // Para seleccionar un stop concreto. Se crea y se pinta.

export const BERTHS_LAYER_ID = 'berthsLayer'; // Capa de atraques.
export const BOLLARDS_LAYER_ID = 'bollardsLayer'; // Capa de bolardos.

// Layer AIS
export const AIS_VESSEL_LAYER_ID = 'aisVesselLayer';

// Group Layer
export const AIS_VESSEL_GROUP_ID = 'aisVesselGroup';
export const STOP_PREVISION_GROUP_ID = 'stopPrevisionGroup'; // Grupo de capas de prevision

// Layers Prevision
export const STOP_ACCEPTED_LAYER_ID = 'stopAcceptedLayer'; // ¿Capa de accepted + requested? (REQUESTED)
export const STOP_PLANNED_LAYER_ID = 'stopPlannedLayer'; // Capa de planificados
export const STOP_AUTHORIZED_LAYER_ID = 'stopAuthorizedLayer'; // Capa de autorizaciones.
export const STOP_CONFIRMED_LAYER_ID = 'stopConfirmedLayer'; // Capa de autorizaciones.
export const STOP_INITIATED_LAYER_ID = 'stopInitiatedLayer'; // Capa de iniciados

// Layers Filtro AIS
export const FILTER_AIS_VISIT_LAYER_ID = 'filterAisVisitLayer'; // Filtro AIS Visit
export const FILTER_SERVICE_LAYER_ID = 'filterServiceLayer'; // Filtro Servicio
export const FILTER_OTHER_VESSEL_LAYER_ID = 'filterOtherVesselLayer'; // Filtro Otros buques
export const FILTER_HIGH_FREQUENCY_ID = 'filterHighFrenquencyLayer'; // Filtro Otros buques

// Layers Map Situacion de Buques
export const STOP_INSTANT_LAYER_ID = 'stopInstantLayer'; // Capa de situación de buques

// Cep layers
export const CEP_ZONES_ID = 'cepZonesLayer'; // Capa de zonas CEP
