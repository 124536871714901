<template>
	<v-col cols="12" v-if="attributes.length > 0">
		<pui-field-set :title="$t('form.resources.dynamics')">
			<v-row dense>
				<v-col
					v-show="attribute.disabled == 0"
					v-for="(attribute, index) in attributes"
					:key="attribute.id"
					:md="attribute.typeattributeid === 3 ? 12 : 3"
					class="align-self-center"
				>
					<pui-text-field
						v-if="attribute.typeattributeid === 1"
						:id="`dynamic-text-${index}-${parentModelName}`"
						:label="attribute.description"
						v-model="attribute.attributevalue.valuestring"
						maxlength="100"
						toplabel
						:disabled="formDisabled"
						:required="attribute.required == 1"
					></pui-text-field>
					<pui-checkbox
						v-else-if="attribute.typeattributeid === 2"
						:id="`dynamic-check-${index}-${parentModelName}`"
						:label="attribute.description"
						:true-value="true"
						v-model="attribute.attributevalue.valueboolean"
						:false-value="false"
						:disabled="formDisabled"
					></pui-checkbox>
					<pui-text-area
						v-else-if="attribute.typeattributeid === 3"
						:id="`dynamic-textarea-${index}-${parentModelName}`"
						:label="attribute.description"
						v-model="attribute.attributevalue.valuestring"
						toplabel
						:disabled="formDisabled"
						:required="attribute.required == 1"
					></pui-text-area>
					<pui-date-field
						v-else-if="attribute.typeattributeid === 4"
						:id="`dynamic-date-${index}-${parentModelName}`"
						:label="attribute.description"
						v-model="attribute.attributevalue.valuedate"
						toplabel
						:required="attribute.required == 1"
						:disabled="formDisabled"
					></pui-date-field>
					<pui-select
						v-else-if="attribute.typeattributeid === 5"
						:id="`dynamic-select-${index}-${parentModelName}`"
						:attach="`dynamic-select-${index}-${parentModelName}`"
						:label="attribute.description"
						clearable
						toplabel
						:disabled="formDisabled"
						:required="attribute.required == 1"
						v-model="attribute.attributevalue"
						modelName="typeattributeoptions"
						:itemsToSelect="[{ id: attribute.attributevalue.valueoption }]"
						:modelFormMapping="{ id: 'valueoption' }"
						:itemValue="['id']"
						:itemText="(item) => `${item.description}`"
						:order="{ sort: 'asc' }"
						:fixedFilter="{
							groups: [],
							groupOp: 'and',
							rules: [{ field: 'attributeid', op: 'eq', data: attribute.id }]
						}"
					></pui-select>
					<pui-number-field
						v-else-if="attribute.typeattributeid === 6"
						:id="`dynamic-number-${index}-${parentModelName}`"
						:label="attribute.description"
						v-model="attribute.attributevalue.valuestring"
						toplabel
						:disabled="formDisabled"
						:required="attribute.required == 1"
					></pui-number-field>
					<pui-select
						v-else-if="attribute.typeattributeid === 7"
						:id="`dynamic-select-${index}-${parentModelName}`"
						:attach="`dynamic-select-${index}-${parentModelName}`"
						:label="attribute.description"
						clearable
						toplabel
						:disabled="formDisabled"
						:required="attribute.required == 1"
						v-model="attribute.attributevalue"
						:modelName="attribute.modeldata"
						:itemsToSelect="[{ id: attribute.attributevalue.valuemodeldataoption }]"
						:modelFormMapping="{ id: 'valuemodeldataoption' }"
						:itemValue="['id']"
						:itemText="(item) => `${item.description}`"
						:order="{ sort: 'asc' }"
						:fixedFilter="
							formDisabled
								? null
								: {
										groups: [],
										groupOp: 'and',
										rules: [{ field: 'downdatetime', op: 'nu' }]
								  }
						"
					></pui-select>
				</v-col>
			</v-row>
		</pui-field-set>
	</v-col>
</template>

<script>
export default {
	name: 'custom-fields-generate-form',
	data() {
		return {
			attributes: []
		};
	},
	props: {
		formDisabled: {
			type: Boolean,
			required: true
		},
		isCreatingElement: {
			type: Boolean,
			required: true
		},
		parentObject: {
			type: Object,
			required: true
		},
		parentModelName: {
			type: String,
			required: true
		},
		parentPk: {
			type: String,
			required: true
		}
	},
	created() {
		if (this.has_READ_ATTRIBUTE) {
			let pk = JSON.parse(atob(this.parentPk));
			const pkName = Object.keys(pk)[0];
			const opts = {
				model: this.parentModelName,
				pk: pk[pkName]
			};
			this.$puiRequests.getRequest('/attribute/getAttributes', opts, (response) => {
				if (response.data) {
					this.attributes = response.data;
				}
			});
			this.$puiEvents.$on(`onPui-insertRow-dataTable-${this.parentModelName}`, (data) => {
				let pk = '';
				if (data.id) {
					pk = data.id;
				} else if (data.objectperu) {
					pk = data.objectperu.id;
				} else {
					const pkColumn = this.$store.getters.getModelByName(this.parentModelName).columns.find((column) => column.isPk === true);
					if (pkColumn) {
						pk = data[pkColumn.name];
					}
				}
				this.$puiRequests.postRequest('/attribute/insertAttributesValue', this.attributes, null, null, null, { pk: pk });
			});
			this.$puiEvents.$on(`onPui-editRow-dataTable-${this.parentModelName}`, (data) => {
				let pk = '';
				if (data.id) {
					pk = data.id;
				} else if (data.objectperu) {
					pk = data.objectperu.id;
				} else {
					const pkColumn = this.$store.getters.getModelByName(this.parentModelName).columns.find((column) => column.isPk === true);
					if (pkColumn) {
						pk = data[pkColumn.name];
					}
				}
				this.$puiRequests.postRequest('/attribute/insertAttributesValue', this.attributes, null, null, null, { pk: pk });
			});
		}
	},
	beforeDestroy() {
		if (this.has_READ_ATTRIBUTE) {
			this.$puiEvents.$off(`onPui-insertRow-dataTable-${this.parentModelName}`);
			this.$puiEvents.$off(`onPui-editRow-dataTable-${this.parentModelName}`);
		}
	},
	methods: {},
	computed: {
		has_READ_ATTRIBUTE() {
			return this.$store.getters.hasFunctionality('READ_ATTRIBUTE');
		}
	}
};
</script>

<style scoped></style>
