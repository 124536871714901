import { TYPE_VIS, STATUS_VIS } from '@/components/operations/map/sections/map/subcomponents/mapVariables';
import {
	FEC_TODAY,
	FEC_PAST_INI,
	FEC_PAST_END,
	FEC_FUTURE_END,
	FEC_REQPL_INI
} from '@/components/operations/map/sections/infoperiods/InitialInstantDates';
export default {
	data() {
		return {
			signout: 0,
			isSessionClosed: false
		};
	},

	created() {
		if (!this.isSessionClosed && this.$store) {
			this.signout = this.$store.state.login.signout;
			this.isSessionClosed = false;
		}
	},

	beforeDestroy() {
		if (this.$store && !this.$store.state.login.mixinExecuted) {
			if (this.signout !== this.$store.state.login.signout) {
				this.onSessionClose();
			}

			this.signout = this.$store.state.login.signout;
			this.$store.commit('setMixinExecuted', true);
		}
	},

	methods: {
		onSessionClose() {
			this.isSessionClosed = true;
			// El orden es importante
			this.$store.commit('cleanTabs');
			this.$store.commit('clearAllLayersAISList');
			this.$store.commit('setPortAutorityHaveStatusPl', false);
			window.localStorage.setItem('stopsStatusValues', null);
			window.localStorage.setItem('visitsStatusValues', null);
			this.$store.commit('setStopsTypeValues', null);
			this.$store.commit('setMapAutorityExtent', null);
			this.$store.commit('setMapAisFitPortExtent', null);
			this.$store.commit('setMapPeriodsFitPortExtent', null);
			this.$store.commit('setMapAisPortVisualization', 0);
			this.$store.commit('setMapPeriodsPortVisualization', 0);
			this.$store.commit('setMapAisStyleVisualization', TYPE_VIS);
			this.$store.commit('setMapPeriodsStyleVisualization', STATUS_VIS);
			this.$store.commit('setStopsFeaturesAccepted', []);
			this.$store.commit('setStopsFeaturesPlanned', []);
			this.$store.commit('setStopsFeaturesAuthorized', []);
			this.$store.commit('setStopsFeaturesConfirmed', []);
			this.$store.commit('setStopsFeaturesInitiated', []);

			// Searching text clean
			this.$store.commit('setSearchingText', '');
			this.$store.commit('setSearchingPastText', '');
			this.$store.commit('setSearchingFutureText', '');
			this.$store.commit('setSearchingRequestedText', '');
			this.$store.commit('setSearchingPlanningText', '');

			// Planning clean
			this.$store.commit('setStopsFeaturesInstantPL', []);
			this.$store.commit('setStopsFeaturesInstantModifiedPL', []);
			this.$store.commit('setStopsFeaturesInstantWithModifiedPL', []);
			this.$store.commit('setStopsFeaturesInstantRemovedPL', []);

			// Authorization clean
			this.$store.commit('setStopsFeaturesInstantAU', []);
			this.$store.commit('setStopsFeaturesInstantModifiedAU', []);
			this.$store.commit('setStopsFeaturesInstantWithModifiedAU', []);
			this.$store.commit('setStopsFeaturesInstantRemovedAU', []);

			// Instant planning dates
			this.$store.commit('setFecIniPastpanel', FEC_PAST_INI);
			this.$store.commit('setFecEndPastpanel', FEC_PAST_END);
			this.$store.commit('setFecIniFuturepanel', FEC_TODAY);
			this.$store.commit('setFecEndFuturepanel', FEC_FUTURE_END);
			this.$store.commit('setFecIniRequestedspanel', FEC_TODAY);
			this.$store.commit('setFecEndRequestedspanel', FEC_REQPL_INI);
			this.$store.commit('setFecIniPlanningpanel', FEC_TODAY);
			this.$store.commit('setFecEndPlanningpanel', FEC_REQPL_INI);
		}
	}
};
