<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.customfields.configFields') + ' ' + $t('menu.' + modelParent)"
			modelName="customFields"
			:dialogName="configFields"
			:showDialogProp="showConfigFields"
			:overflow="true"
			:disableCancel="true"
			:disableOk="true"
			:onOk="onOk"
			:onShow="onShow"
			:widthDialog="1300"
		>
			<template slot="message" slot-scope="data">
				<pui-quick-edition-datatable
					:parentModelName="data.modalData.model"
					:parentPk="pk(data.modalData.model)"
					:parentPkChildFk="{ model: 'model' }"
					modelName="attribute"
					formPosition="bottom"
					:disabled="false"
					formComponentName="attributequickform"
					:parentModel="data.modalData"
					:modelColumnDefs="modelColumnDefs"
				></pui-quick-edition-datatable>
				<v-btn style="float: right" :id="`${configFields}-btn-ok`" depressed @click="onOk">{{ $t('modal.customfields.close') }}</v-btn>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'customfields-modals',
	mixins: [],
	components: {},
	data() {
		return {
			configFields: 'configFields',
			showConfigFields: true,
			modelParent: '',
			modelColumnDefs: {
				disabled: {
					render: (data, type, row) => {
						return row.disabled == 0 ? `<i class="fas fa-check state-check"></i>` : '';
					}
				}
			}
		};
	},
	computed: {},
	watch: {},
	methods: {
		pk(model) {
			return btoa(JSON.stringify({ model: model }));
		},
		onShow(modalData) {
			this.showConfigFields = true;
			this.modelParent = modalData.model;
		},
		onOk() {
			this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelParent}`);
			this.showConfigFields = false;
		}
	}
};
</script>
<style lang="postcss"></style>
